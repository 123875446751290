<template>
  <div
    id="app-wrapper"
    class="d-flex flex-column"
  >
    <server />
    <div
      id="app-content"
      ref="content"
      class="flex-fill overflow-hidden"
    >
      <div class="top-content" />
      <transition
        :name="routerTransition"
        mode="out-in"
      >
        <slot />
      </transition>
    </div>
    <Menu />
  </div>
</template>

<script>
import AppServer from '@core/components/app-server/AppServer.vue'
import Menu from '@core/layouts/components/Menu.vue'

export default {
  components: {
    server: AppServer,
    Menu,
  },
  computed: {
    routerTransition() {
      return 'fade'
    },
  },
  watch: {
    $route(to) {
      if (to?.meta?.layout === 'app') {
        this.$refs.content.scrollTo(0, 0)
        setTimeout(() => {
          this.$refs.content.scrollTo(0, 0)
        }, 200)
      }
    },
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
#app-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;

}

#app-content {
  position: relative;
}

.top-content {
  box-shadow: -20px 0px 36px 5px rgb(0 0 0 / 52%);
  position: absolute;
  top: -10px;
  height: 1px;
  width: 100%;
}
</style>
