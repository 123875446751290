<template>
  <div
    id="app-menu"
    ref="menu"
  >
    <router-link
      v-for="(item, key) in navigation"
      :key="key"
      :to="item.route"
      :class="{'active': item.route.name === $router.currentRoute.name || $router.currentRoute.path.indexOf(item.route.path) !== -1}"
      tag="div"
      @click="$router.push(item.route)"
    >
      <i :class="item.icon" />
      <span>{{ item.name }}</span>
    </router-link>
  </div>
</template>

<script>
import Navigation from '@/navigation'

export default {
  computed: {
    navigation() {
      return Navigation
    },
  },
  watch: {
    $route() {
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss" scoped>
#app-menu {
  overflow-y: unset !important;
  overflow-x: unset;
  box-shadow: 0 3px 73px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(112, 112, 112, 0.13);
  background-color: #ffffff;
  padding: 12px 10px calc(5px + calc(env(safe-area-inset-top) / 2)) 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgb(0 0 0 / 13%);
  position: relative;
  z-index: 99;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: 420px) {
    justify-content: space-around;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;

    &.active {
      i, span {
        color: #02bab0;
      }
    }

    i {
      font-size: 1.4em;
      margin-bottom: 8px;
    }

    span {
      font-size: 0.95em;
      font-weight: 300;
      letter-spacing: 0;
      color: #707070;
    }
  }
}
</style>
