import i18n from './libs/i18n'

export default [
  {
    name: `${i18n.t('pages.shipment.now.name')}`,
    icon: 'fa-regular fa-calendar-day',
    route: {
      name: 'now',
      path: '/now',
    },
  },
  {
    name: `${i18n.t('pages.history.name')}`,
    icon: 'fa-regular fa-calendar-lines',
    route: {
      name: 'history',
      path: '/history',
    },
  },
  {
    name: `${i18n.t('pages.campaign.title')}`,
    icon: 'fa-regular fa-chart-simple',
    route: {
      name: 'resume',
      path: '/resume',
    },
  },
  {
    name: `${i18n.t('pages.settings.name')}`,
    icon: 'fa-regular fa-sliders-up',
    route: {
      name: 'settings',
      path: '/settings',
    },
  },
]
